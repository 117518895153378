* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures consistent padding and border behavior */
}
.about-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .about-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .about-item-1 {
    width: 150px;
    height: 300px;
  }
  
  .about-item-2 {
    width: 130px;
    height: 250px;
    margin-top: 100px;
  }
  
  .about-item-3 {
    width: 170px;
    height: 320px;
    margin-top: 50px;
  }
  
  .about-item-4 {
    width: 140px;
    height: 280px;
    margin-top: 150px;
  }
  
  .about-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .about-item:hover img {
    transform: scale(1.1);
  }
  