/* Glassmorphism effect */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures consistent padding and border behavior */
  
}

.glass-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-size: cover;
    background: url('/src/images/onunu1.jpeg') no-repeat center center/cover;
  }
  
  .glass-text {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 20px;
    color: #fff;
    font-family: "Playpen Sans", cursive;
    font-size: 18px;
    line-height: 1.8;
    width: 60%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    text-align: left;
  }
  