/* General Footer Styling */
.footer {
  background-color: #1c1c1c;
  color: #f5f5f5;
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

/* Logo Section */
.logo-section {
  flex: 1 1 100%;
  text-align: center;
}

.logo {
  max-width: 150px;
  margin: 0 auto;
}

/* Quick Links Section */
.quick-links h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #ffa500;
}

.quick-links ul {
  list-style: none;
  padding: 0;
}

.quick-links li {
  margin: 5px 0;
}

.quick-links a {
  color: #f5f5f5;
  text-decoration: none;
  transition: color 0.3s;
}

.quick-links a:hover {
  color: #ffa500;
}

/* Social Media Links Section */
.social-links h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #ffa500;
}

.social-links .icons a {
  margin: 0 10px;
  color: #f5f5f5;
  transition: transform 0.3s;
}

.social-links .icons a:hover {
  transform: scale(1.2);
  color: #ffa500;
}

/* Contact Information Section */
.contact-number h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #ffa500;
}

.contact-number p {
  margin: 5px 0;
}

.contact-number a {
  color: #ffa500;
  text-decoration: none;
}

.contact-number a:hover {
  text-decoration: underline;
}

/* Footer Bottom Section */
.footer-bottom {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  color: #b0b0b0;
}

.footer-bottom p {
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    flex: 1 1 auto;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px;
  }

  .quick-links h3,
  .social-links h3,
  .contact-number h3 {
    font-size: 16px;
  }
}
