@import url('https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@100..800&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures consistent padding and border behavior */
}

.homepage {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    background: url('/src/images/onunum.svg') no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .text-container {
    text-align: center;
    font-family: "Playpen Sans", cursive;
    margin-bottom: 20px; /* Space between text and buttons */
  }
  
  .festival-title {
    font-size: 48px;
    font-weight: bold;
    font-family: "Playpen Sans", cursive;
    margin-right: 150px;
  }
  
  .onunu {
    color: yellow;
  }
  .onunu-text{
    color: red;
    font-size: 48px;
    margin-right: 150px;
  }
  
  .festival {
    color: white;
  }
  
  .love-jesus {
    font-size: 24px;
    color: white;
    margin-top: 10px;
  }
  
  .button-container {
    display: flex;
    gap: 20px; /* Space between buttons */
    margin-right: 150px;
  }
  
  .btn {
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    outline: none;
  }
  .btn-link {
    text-decoration: none; /* Remove default underline from anchor tags */
    display: inline-block; /* Ensures proper spacing around the buttons */
  }
  
  .btn:hover {
    background-color: black; /* Button hover effect */
  }
  
  .become-vendors {
    background-color: yellow;
    color: black;
  }
  
  .buy-ticketss {
    background-color: black;
  }
  
  .become-vendors:hover {
    background-color: yellow;
  }
  
  .buy-ticketss:hover {
    background-color: black;
  }
  
  .countdown {
    display: flex;
    width: 300px;
    gap: 20px; /* Increase space between countdown items */
    font-size: 20px; /* Increase font size */
    margin-top: 30px; /* Add more space above the countdown */
    background: rgba(0, 0, 0, 0.7); /* Dark background to enhance contrast */
    padding: 20px; /* Add padding for better visual separation */
    border-radius: 10px; /* Rounded corners for the timer background */
    margin-left: 150px;
   }
  
  .countdown-item {
    text-align: center;
  }
  
  .countdown-number {
    display: block;
    font-size: 28px; /* Increase size of the numbers */
    font-weight: bold;
    color: yellow; /* Enhanced color for visibility */
  }
  
  .countdown-label {
    display: block;
    font-size: 20px; /* Increase size of the labels */
    color: white;
  }
  
  /* Media query for smaller screens (480px and below) */
  @media (max-width: 430px) { 
    .homepage {
      height: 60vh; /* Slightly reduce height */
      width: 100%;
    }
    
    .overlay {
      padding: 0 2px; /* Further reduce padding */
    }
  
    .festival-title {
      font-size: 28px; /* Further reduce font size */
      margin-right: 20px; /* Reduced margin */
    }
  
    .onunu-text {
      font-size: 26px; /* Further reduce font size */
      margin-right: 15px; /* Reduced margin */
    }
  
    .love-jesus {
      font-size: 14px; /* Reduced font size */
    }
  
    .button-container {
      flex-direction: row; /* Buttons remain stacked horizontally */
      gap: 10px; /* Further reduce gap between buttons */
      display: flex;
      margin-right: 20px; /* Reduced margin */
    }
  
    .btn {
      width: 90%; /* Reduced button width slightly */
      padding: 10px 12px; /* Adjusted padding */
      font-size: 12px; /* Further reduce font size */
      display: flex;
    }
  
    .countdown {
      font-size: 14px; /* Reduced font size */
      gap: 10px; /* Further reduce gap between countdown items */
      padding: 10px; /* Adjust padding */
      margin-left: 20px; /* Reduced margin */
    }
  
    .countdown-number {
      font-size: 18px; /* Reduced number size */
    }
  
    .countdown-label {
      font-size: 14px; /* Reduced label size */
    }
  }
  