* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures consistent padding and border behavior */
}

.team-wrapper {
    text-align: center;
    margin: 20px;
  }
  
  h1 {
    font-size: 2rem;
    margin-left: 30px;
    font-family: "Playpen Sans", cursive;
  }
  
  .header-vector {
    flex-shrink: 0;
    position: absolute;
    top: 700px;
    right: -50px;
  }
  
  .team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
  }
  
  .team-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    margin-bottom: 20px; /* Add space for the button below the image */
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .team-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: transform 0.3s ease;
    border-radius: 10px 10px 0 0; /* Rounded only at the top */
  }
  
  .team-item:hover .team-image {
    transform: scale(1.1);
  }
  
  .image-button {
    background-color: var(--onuboy-yellow-tint06, #FCE303);
    border: none;
    padding: 10px 15px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    font-family: "Playpen Sans", cursive;
    transition: background-color 0.3s ease;
  }
  
  .image-button:hover {
    background-color: #000;
    color: #fff;
  }
  
  .info-text {
    margin-top: 20px;
    font-family: "Playpen Sans", cursive;
  }
  
  .info-text p {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .info-button {
    background: var(--onuboy-yellow-tint06, #FCE303);
    display: flex;
    width: 160px;
    padding: 10px;
    margin-left: 50%;
    transform: translateX(-50%);
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .info-button:hover {
    background-color: #000;
    color: #fff;
  }
  
  .info-vector {
    flex-shrink: 0;
    position: absolute;
    top: 900px;
    left: -300px;
    height: 380px;
    margin-left: 300px;
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .team-item {
      width: 200px;
    }
  
    .team-image {
      height: 200px;
    }
  
    .info-text p {
      font-size: 20px;
    }
  
    .info-button {
      width: 140px;
    }
  
    .header-vector {
      top: 650px;
      right: -20px;
      width: 50px;
    }
  
    .info-vector {
      top: 750px;
      left: -200px;
      height: 280px;
      margin-left: 200px;
    }
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: 1.8rem;
      margin-left: 10px;
    }
  
    .team-item {
      width: 180px;
    }
  
    .team-image {
      height: 180px;
    }
  
    .info-text p {
      font-size: 18px;
    }
  
    .info-button {
      width: 130px;
      padding: 8px;
    }
  
    .header-vector {
      top: 600px;
      right: 0;
      width: 50px;
    }
  
    .info-vector {
      top: 600px;
      left: -150px;
      height: 240px;
      margin-left: 150px;
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 1.5rem;
      margin-left: 0;
    }
  
    .team-item {
      width: 150px;
    }
  
    .team-image {
      height: 150px;
    }
  
    .info-text p {
      font-size: 16px;
    }
  
    .info-button {
      width: 120px;
      padding: 6px;
    }
  
    .header-vector {
      top: 700px;
      right: 10px;
      width: 50px;
    }
  
    .info-vector {
      top: 600px;
      left: -150px;
      height: 240px;
      margin-left: 150px;
    }
  }
