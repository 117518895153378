* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures consistent padding and border behavior */
}

.header {
    width: 1250px;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 0 auto; /* Center the header */
    background-color: #f8f9fa; /* Example background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow */
    overflow: hidden;
  }
  
  .header-left,
  .header-right {
    display: flex;
    align-items: center;
    gap: 50px;
  }
  
  .header-center {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
  
  .logo {
    max-height: 100px; /* Adjust according to your design */
  }
  
  .header-link {
    margin: 0 15px;
    text-decoration: none;
    color: black;
    font-size: 16px;
  }
  
  .buy-tickets {
    padding: 10px 20px;
    background-color: #FCE303; /* Example button color */
    color: white;
    border: none;
    width: 178px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 15px;
    font-size: 16px;
  }
  
  .buy-tickets:hover {
    background-color: black; /* Darker shade on hover */
  }
  
  /* Hide the hamburger icon by default */
  .hamburger {
    display: none;
  }
  
  @media (max-width: 1024px) {
    .header {
      flex-direction: column; /* Stack the header items */
      height: auto; /* Let height adjust dynamically */
      padding: 10px;
    }
  
    /* Adjust Logo Size for Smaller Screens */
    .logo {
      max-height: 70px; /* Reduce the logo size */
      margin-right: 1100px;
    }
  
    /* Header Links */
    .header-left,
    .header-right {
      display: none; /* Hide links for smaller screens (use hamburger instead) */
    }
  
    /* Show Hamburger Icon on Small Screens */
    .hamburger {
      display: block;
      cursor: pointer;
      font-size: 44px;
      position: absolute;
      top: 50px;
      margin-right: -200px; /* Push to the right */
    }
  
    /* Adjust Buy Tickets Button for Small Screens */
    .buy-tickets {
      width: 100%; /* Full-width button on small screens */
      margin: 10px 0; /* Add margin around the button */
    }
    
    .mobile-menu {
      display: flex;
      flex-direction: column; /* Display links vertically */
      align-items: center;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7); /* Adjust opacity with the alpha value */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 10px 0;
      z-index: 999;
    }
    .header-link{
      color: #f8f9fa;
      margin: 10px 0;
    }
    .mobile-menu 
    .mobile-menu .buy-tickets {
      margin: 10px 0; /* Space out items vertically */
    }
  }
  @media (max-width: 768px) {
    .header {
      flex-direction: column; /* Stack the header items */
      height: auto; /* Let height adjust dynamically */
      padding: 10px;
    }
  
    /* Adjust Logo Size for Smaller Screens */
    .logo {
      max-height: 70px; /* Reduce the logo size */
      margin-right: 1100px;
    }
  
    /* Header Links */
    .header-left,
    .header-right {
      display: none; /* Hide links for smaller screens (use hamburger instead) */
    }
  
    /* Show Hamburger Icon on Small Screens */
    .hamburger {
      display: block;
      cursor: pointer;
      font-size: 44px;
      position: absolute;
      top: 40px;
      margin-right: -200px; /* Push to the right */
    }
  
    /* Adjust Buy Tickets Button for Small Screens */
    .buy-tickets {
      width: 100%; /* Full-width button on small screens */
      margin: 10px 0; /* Add margin around the button */
    }
    
    .mobile-menu {
      display: flex;
      flex-direction: column; /* Display links vertically */
      align-items: center;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7); /* Adjust opacity with the alpha value */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 10px 0;
      z-index: 999;
    }
    .header-link{
      color: #f8f9fa;
      margin: 10px 0;
    }
    .mobile-menu 
    .mobile-menu .buy-tickets {
      margin: 10px 0; /* Space out items vertically */
    }
  }
  /* Mobile styles */
  @media (max-width: 480px) {
    .header {
      width: 100%;
    padding: 0 5px; /* Reduced padding */
    height: 80px; /* Reduced height */
    flex-direction: column;
    align-items: flex-start;
    gap: 20px; /* Reduced gap */
    color: #f8f9fa;;
    }
  
    .header-left,
    .header-right {
      display: none; /* Hide the header links on smaller screens */
    }
  
    .header-center {
      margin: 0 auto; /* Center the logo */
    }
    
    .logo {
        margin-right: 350px;
      }
  
      .hamburger {
        display: block;
        width: 30px;
        height: 30px;
        cursor: pointer;
        margin-left: 380px; /* Adjust this value if needed for horizontal alignment */
        margin-bottom: 0; /* Remove or reduce this value to move the icon up */
        position: absolute; /* Positioning it absolutely to control its position more precisely */
        top: 27px; /* Adjust this value to control how far from the top you want it to be */
      }
      
  
      .mobile-menu {
        display: flex;
        flex-direction: column; /* Display links vertically */
        align-items: center;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7); /* Adjust opacity with the alpha value */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 10px 0;
        z-index: 999;
      }
      .header-link{
        color: #f8f9fa;
        margin: 10px 0;
      }
      .mobile-menu 
      .mobile-menu .buy-tickets {
        margin: 10px 0; /* Space out items vertically */
      }
      
  }
  