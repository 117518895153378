* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures consistent padding and border behavior */
}
@import url('https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@100..800&display=swap');
.gallery-wrapper {
  text-align: center;
  margin: 20px;
}

h1 {
  font-size: 2.0rem;
  margin-left: 30px; /* Add space between the heading and the vector */
  font-family: "Playpen Sans", cursive;
}


.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
}

.gallery-item {
  width: 250px;
  height: 250px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border-radius: 10px 50px;
}

.gallery-item:hover {
  transform: scale(1.2);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.2);
}

.about-text {
  margin-top: 20px;
  font-family: "Playpen Sans", cursive;
}

.about-text p {
  font-size: 24px;
  margin-bottom: 10px;
  font-family: "Playpen Sans", cursive;
}

.about-button {
  background: var(--onuboy-yellow-tint06, #FCE303);
  display: flex;
  width: 160px;
  padding: 10px;
  margin-left: 50%;
  transform: translateX(-50%); /* Center button horizontally */
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.about-button:hover {
  background-color: #000;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .gallery-item {
    width: 200px;
    height: 200px;
  }

  .about-text p {
    font-size: 20px;
  }

  .about-button {
    width: 140px;
  }

  
}

@media (max-width: 768px) {
  h1 {
    font-size: 1rem;
    margin-left: 200px;
  }

  .gallery-item {
    width: 180px;
    height: 180px;
  }

  .about-text p {
    font-size: 18px;
  }

  .about-button {
    width: 130px;
    padding: 8px;
  }

 
}

@media (max-width: 480px) {
  h1 {
    font-size: 1rem;
    margin-left: 0;
  }

  .gallery-item {
    width: 90px;
    height: 90px;
  }

  .about-text p {
    font-size: 13px;
  }

  .about-button {
    width: 90px;
    font-size: 10px;
    padding: 6px;
  }

 
}
